@import "../../../assets/theme/_var.scss";

.sidebar {
    width: 100%;
    transition: 0.3s ease-in-out;


    @media (max-width:1199px) {
        transform: translate(-100%);
        max-width: 30rem;
        position: fixed;
        height: 100vh;
        overflow: auto;
        top: 0;
        left: 0;
        z-index: $sidebar;

        @include theme() {
            background: theme-get("sidebar");
        }


        &.active {
            transform: translateX(0);
        }
    }

    .sidebar_head {
        display: none;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 4rem 0 4.6rem;

        @media (max-width: 1679px) {
            padding: 1.5rem 2rem;
        }

        @media (max-width: 1199px) {
            display: flex;
        }

        img {
            width: 17.9rem;
            height: 4.9rem;
        }

        .close_btn {
            background-color: transparent;
            padding: 0;
            border: none;

            svg {
                width: 2.5rem;
                height: 2.5rem;
            }

            @media (max-width: 1199px) {
                width: 3.2rem;

                height: 3.2rem;

                &::before,
                &::after {
                    content: "";
                    width: 3.2rem;
                    height: 0.3rem;
                    display: block;
                    transform-origin: left;
                    transform: rotate(45deg);

                    @include theme() {
                        background-color: theme-get("textclr");
                    }
                }

                &::after {
                    margin-top: 1.9rem;
                    transform: rotate(-45deg);
                }

                svg {
                    display: none;
                }
            }
        }

    }


    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1199px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 2rem;
        }

        li {
            position: relative;

            @media (max-width: 1199px) {
                padding: 0 2rem;
            }

            a {
                padding: 0 1.5rem;
                height: 6rem;
                position: relative;
                display: flex;
                align-items: center;
                font-size: 1.6rem;
                font-weight: 600;
                width: 100%;
                border-radius: .8rem;
                transition: 0.3s ease-in-out;

                @include theme() {
                    color: theme-get("textclr");
                }

                &:hover,
                &.active {

                    background: linear-gradient(92.62deg,
                            rgba(143, 91, 255, 1) 0%,
                            rgba(0, 87, 255, 1) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: 0.3s ease-in-out all;

                    &::after {
                        opacity: 1;
                    }

                    &::before {
                        width: 100%;
                    }

                }

            }

        }
    }
}

.sidebar_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1rem);
    z-index: $sidebar_overlay;
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease-in-out;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}