.ct-toast-success {
  h4.ct-heading {
    color: #000 !important;
  }
}

.ct-toast-error {
  h4.ct-heading {
    color: #000 !important;
  }
}

.ct-toast-info {
  h4.ct-heading {
    color: #000 !important;
  }
}

.ct-toast {
  svg {
    width: 20px;
    height: 20px;
  }
}
